
import { Vue } from "vue-property-decorator";
import fileApi from "@/api/file/index";
import api from "@/api/killrecord.ts";
export default class extends Vue {
  submitform = {
    title: "",
    remark: "",
    fileIds: "",
  };
  uploader = [];

  afterRead(files: any) {
    let filearr = null;
    if (!Array.isArray(files)) {
      filearr = [files];
    } else {
      filearr = [...files];
    }
    filearr.map((file) => {
      file.status = "uploading";
      file.message = "上传中...";
      fileApi
        .uploadFile("inspectionmobile/", file.file.name, file.file, true)
        .then((res: any) => {
          file.status = "done";
          file.id = res.data.id;
        });
    });
  }

  onSubmit() {
    let arr = "";
    this.uploader.map((item: any) => {
      arr += "," + item.id;
    });
    this.submitform.fileIds = arr.substr(1, arr.length - 1);
    api
      .projectDisinfectRecordsave({
        projectId: this.$store.state.project.projectinfo.projectId,
        ...this.submitform,
      })
      .then((res: any) => {
        if (res.code === 0) {
          this.$toast.success({
            message: res.msg,
            forbidClick: true,
            onClose: () => {
              this.$router.go(-1);
            },
          });
        } else {
          this.$toast.fail(res.msg);
        }
      });
  }
  fSubmit() {
    (this as any).$refs.form.submit();
  }
}
