import request from "@/utils/request";

export default {
  // 新增消杀记录
  projectDisinfectRecordsave(data: any) {
    return request({
      url: "/project/projectDisinfectRecord/save",
      method: "post",
      data: data,
    });
  },
  // 删除消杀记录
  projectDisinfectRecordremove(id: any) {
    return request({
      url: "/project/projectDisinfectRecord/remove/" + id,
      method: "get",
    });
  },
  // 查询消杀记录
  projectDisinfectRecordgetById(id: any) {
    return request({
      url: "/project/projectDisinfectRecord/getById/" + id,
      method: "get",
    });
  },
  // 消杀记录列表
  projectDisinfectRecordpage(data: any) {
    return request({
      url: "/project/projectDisinfectRecord/page",
      method: "post",
      data: data,
    });
  },
  // 当前人员消杀记录列表
  projectDisinfectRecordgetOwnList(data: any) {
    return request({
      url: "/project/projectDisinfectRecord/getOwnList",
      method: "post",
      data: data,
    });
  },
};
