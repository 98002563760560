import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-42798ff1"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "page" }
const _hoisted_2 = { class: "content" }
const _hoisted_3 = { class: "formbox" }
const _hoisted_4 = { class: "btn-box" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_van_nav_bar = _resolveComponent("van-nav-bar")!
  const _component_van_field = _resolveComponent("van-field")!
  const _component_van_uploader = _resolveComponent("van-uploader")!
  const _component_van_cell_group = _resolveComponent("van-cell-group")!
  const _component_van_form = _resolveComponent("van-form")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_van_nav_bar, {
      "left-arrow": "",
      title: _ctx.$route.meta.title,
      placeholder: "",
      onClickLeft: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.go(-1)))
    }, null, 8, ["title"]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_van_form, {
          ref: "form",
          onSubmit: _ctx.onSubmit,
          "show-error-message": false,
          "show-error": true
        }, {
          default: _withCtx(() => [
            _createVNode(_component_van_cell_group, { inset: "" }, {
              default: _withCtx(() => [
                _createVNode(_component_van_field, {
                  modelValue: _ctx.submitform.title,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.submitform.title) = $event)),
                  name: "标题",
                  label: "标题",
                  required: "",
                  placeholder: "请填写标题",
                  rules: [{ required: true, message: '请填写用户名' }]
                }, null, 8, ["modelValue"]),
                _createVNode(_component_van_field, {
                  modelValue: _ctx.submitform.remark,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.submitform.remark) = $event)),
                  maxlength: "200",
                  rows: "2",
                  autosize: "",
                  type: "textarea",
                  "show-word-limit": "",
                  name: "备注",
                  label: "备注",
                  placeholder: "请填写备注",
                  rules: [{ required: true, message: '请填写备注' }]
                }, null, 8, ["modelValue"]),
                _createVNode(_component_van_field, { label: "附件" }, {
                  input: _withCtx(() => [
                    _createVNode(_component_van_uploader, {
                      modelValue: _ctx.uploader,
                      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.uploader) = $event)),
                      "after-read": _ctx.afterRead,
                      multiple: _ctx.$isIOS()
                    }, null, 8, ["modelValue", "after-read", "multiple"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["onSubmit"])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", {
          class: "submit-btn",
          onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.fSubmit && _ctx.fSubmit(...args)))
        }, "提交")
      ])
    ])
  ]))
}